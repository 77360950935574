import type { ArticleCardProps } from '@which/seatbelt'

import { getImageSources } from '../../../shared/utils/getImageSources'

const sizes = '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw'

const ratio = 'ar_2to1'
const renditions = [
  '320x160',
  '500x250',
  '700x350',
  '900x450',
  '1200x600',
  '1500x750',
  '1800x900',
  '2100x1050',
  '2400x1200',
  '2700x1350',
  '3000x1500',
  '3300x1650',
  '3600x1800',
]

export const section = 'Membership offers'

export const magazineLinkList: ArticleCardProps[] = [
  {
    href: 'https://signup.which.co.uk/wlp-gardening',
    image: 'https://media.product.which.co.uk/prod/images/original/34b3b4e0a02a-artboard-1.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('34b3b4e0a02a-artboard-1', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Get the garden you want',
    title: 'Which? Gardening',
  },
  {
    href: 'https://signup.which.co.uk/wlp-travel',
    image:
      'https://media.product.which.co.uk/prod/images/original/edb9d46809f8-artboard-1-copy-2.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('edb9d46809f8-artboard-1-copy-2', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Plan better holidays',
    title: 'Which? Travel',
  },
  {
    href: 'https://signup.which.co.uk/wlp-money',
    image:
      'https://media.product.which.co.uk/prod/images/original/d2225f89a7fc-artboard-1-copy.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('d2225f89a7fc-artboard-1-copy', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Make your money work harder',
    title: 'Which? Money',
  },
  {
    href: 'https://signup.which.co.uk/wlp-tech-support',
    image:
      'https://media.product.which.co.uk/prod/images/original/82bb5824378b-artboard-1-copy-3.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('82bb5824378b-artboard-1-copy-3', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Keep on top of your tech',
    title: 'Which? Tech Support',
  },
]
