import { Button, CardContainer, Heading, TypographyV2 } from '@which/seatbelt'

import type { MemberBenefitRenewalOffer } from '../../../../../../generated/frontend'
import styles from './RenewalOfferBox.module.scss'

export type RenewalOfferBoxProps = {
  offer: MemberBenefitRenewalOffer
}

export const RenewalOfferBox = ({
  offer: { body, phoneNumber, footerText, heading },
}: RenewalOfferBoxProps) => {
  return (
    <div data-testid={'renewal-offer-box-container'} className={styles.offerBoxContainer}>
      <CardContainer className={styles.cardContainer}>
        <div className={styles.offerContent}>
          <TypographyV2 tag="h3" textStyle="sb-text-heading-overline" className={styles.overline}>
            New Offer
          </TypographyV2>
          <Heading heading={heading} headingTag="h1" headingType="medium" />
          <TypographyV2 textStyle="sb-text-body-default-regular">{body}</TypographyV2>
          <Button
            appearance="secondary"
            onClick={() => {
              window.open(`tel:${phoneNumber.replace(/\s/g, '')}`)
            }}
          >
            {`Call ${phoneNumber}`}
          </Button>
          <TypographyV2
            textStyle="sb-text-interface-body-small-regular"
            className={styles.buttonCaption}
          >
            {footerText}
          </TypographyV2>
        </div>
        <div className={styles.redBlock} />
      </CardContainer>
    </div>
  )
}
