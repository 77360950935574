import { Heading } from '@which/seatbelt'
import type { MemberBenefitRenewalOffer } from '@which/transform/build/generated/backend'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { MemberBenefitOffer } from '../../../../../../generated/frontend'
import { OfferBox } from '../OfferBox/OfferBox'
import { RenewalOfferBox } from '../RenewalOfferBox/RenewalOfferBox'
import styles from './PersonalisedMemberBenefitsLoggedIn.module.scss'

const MemberOffer = ({
  offer,
  sectionTitle,
}: {
  sectionTitle: string
  offer: MemberBenefitOffer
}) => {
  /* Note: although the type is not nullable, it could still get null at runtime due to the Code-Gens use of Maybe<> type */
  if (offer.text === null || offer.href === null) {
    return null
  }

  return (
    <div className={styles.panelSection}>
      <Heading headingTag="h3" headingType="small" heading="Member exclusive offer:" />
      <OfferBox
        offer={offer}
        trackingData={{
          'data-which-id': 'Homepage Card',
          'data-card-name': offer.heading,
          'data-section': sectionTitle,
        }}
      />
    </div>
  )
}

const RenewalOfferExperiment = ({
  offer,
  renewalOffer,
  sectionTitle,
}: {
  offer?: MemberBenefitOffer
  renewalOffer: MemberBenefitRenewalOffer
  sectionTitle: string
}) => {
  const shouldShowRenewalOffer = useFeatureIsOn('DSP-008-member-benefits-renewal-offer')

  if (shouldShowRenewalOffer) {
    return <RenewalOfferBox offer={renewalOffer} />
  }

  if (offer) {
    return <MemberOffer offer={offer} sectionTitle={sectionTitle} />
  }

  return null
}

export const OfferContainer = ({
  offer,
  renewalOffer,
  sectionTitle,
}: {
  offer?: MemberBenefitOffer
  renewalOffer?: MemberBenefitRenewalOffer
  sectionTitle: string
}) => {
  // This field will be set for users on our list - include them in the experiment.
  if (renewalOffer) {
    return (
      <div className={styles.panelSection}>
        <RenewalOfferExperiment
          offer={offer}
          renewalOffer={renewalOffer}
          sectionTitle={sectionTitle}
        />
      </div>
    )
  }

  if (offer) {
    return <MemberOffer offer={offer} sectionTitle={sectionTitle} />
  }

  return null
}
